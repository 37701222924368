import { XPlitkaIcon } from "@RDN794312/xplitka-icons";

import CollectionsList from "@/presentation/components/CollectionsList";
import FiltersAside from "@/presentation/components/FiltersAside";
import MobileFilters from "@/presentation/components/MobileFilters";
import ProductsList from "@/presentation/components/ProductsList";
import ShowMoreButton from "@/presentation/components/ShowMoreButton";
import Tabs from "@/presentation/components/Tabs";
import FiltersAsideSkeleton from "@/presentation/components/ui/FiltersAsideSkeleton";
import { type ItemCollectionViewProps, ThemeColor } from "@/types";

import CategoryDescription from "./components/CategoryDescription";

const ItemCollectionListView: React.FC<ItemCollectionViewProps> = ({
	categoryTitle,
	handleTabChange,
	listType,
	isProducts,
	productsLoading,
	collectionsLoading,
	collections = [],
	products = [],
	handleFetchMore,
	filters,
	handleSelectFilter,
	selectedFilters,
	resetFilters,
	categoryDescription,
	filtersLoading,
	closeMobileFilters,
	mobileFiltersOpen,
	openMobileFilters,
	hasNextPage,
	itemsPerPage,
	asH2,
}) => {
	const TitleComponent = asH2 ? "h2" : "h1";
	return (
		<div className="mb-[120px]">
			<TitleComponent className="mt-4 mb-8 text-5xl leading-[60px] font-medium sm:text-3xl sm:mb-6">
				{categoryTitle}
			</TitleComponent>
			<div className="flex w-full gap-6">
				{filtersLoading ? (
					<FiltersAsideSkeleton />
				) : (
					<FiltersAside
						filters={filters}
						handleSelectFilter={handleSelectFilter}
						selectedFilters={selectedFilters}
						resetFilters={resetFilters}
					/>
				)}
				<div className="w-full">
					<div className="w-full mb-6 sm:mb-4">
						<Tabs
							value={listType || "collections"}
							onChange={handleTabChange}
							options={[
								{
									id: "collections",
									title: "Коллекции",
								},
								{
									id: "products",
									title: "Товары",
								},
							]}
						/>
					</div>
					<button
						type="button"
						aria-label="Фильтры"
						onClick={openMobileFilters}
						className="hidden sm:flex items-center mb-6 font-medium h-10 rounded-xl gap-2 border-mediumGray border-[2px] pr-4 pl-2"
					>
						<XPlitkaIcon name="filter" className="relative bottom-px" color={ThemeColor.accentGreen} size={24} />
						<span>Фильтры</span>
					</button>
					<div className="flex flex-col relative">
						{isProducts ? (
							<ProductsList
								products={products}
								isLoading={productsLoading}
								isCategoryPage
								itemsPerPage={itemsPerPage}
							/>
						) : (
							<CollectionsList collections={collections} isLoading={collectionsLoading} itemsPerPage={itemsPerPage} />
						)}
					</div>
					<ShowMoreButton
						onClick={handleFetchMore}
						hasNextPage={hasNextPage}
						className="mt-10"
						isLoading={productsLoading || collectionsLoading}
					/>
				</div>
			</div>
			<CategoryDescription categoryDescription={categoryDescription} />
			<MobileFilters
				closeFilters={closeMobileFilters}
				isOpen={mobileFiltersOpen}
				filters={filters}
				handleSelectFilter={handleSelectFilter}
				selectedFilters={selectedFilters}
				resetFilters={resetFilters}
			/>
		</div>
	);
};

export default ItemCollectionListView;
